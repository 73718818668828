<template>
  <div>
    <DataGrid
      :col="column"
      :dataSource="userList"
      :buttonName="'Add User'"
      :header="{ title: 'Users', img: '' }"
      @onItemClick="onItemClick($event)"
      @clickOnRightButton="addNewUser()"
      @clickCross="removeAppAccess($event)"
    ></DataGrid>
    <DxPopup
      v-bind:visible="popupVisible"
      :drag-enabled="false"
      :hide-on-outside-click="false"
      :show-close-button="false"
      :show-title="true"
      :title="dialogTitle"
      height="auto"
      width="30vw"
    >
      <div>
        <div v-if="dialogTitle == 'Change Password'">
          <v-form ref="password">
            <v-row style="align-items: center">
              <v-col cols="2">
                <v-label>Password</v-label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="password"
                  type="password"
                  placeholder="Enter new password"
                  :rules="validatePassword"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <div class="d-flex">
            <v-btn @click="onChangePassword()" class="add-btn">Save</v-btn>
          </div>
        </div>
        <div v-if="dialogTitle == 'Manage Apps Access'">
          <v-row>
            <v-col cols="4" v-for="access in appAccessTypes" :key="access.key">
              <v-checkbox
                v-model="appPermissions"
                :label="access.text"
                :value="access.text"
              ></v-checkbox>
            </v-col>
            <v-col cols="12">
              <v-btn @click="onSaveAppAccess()" class="add-btn">Save</v-btn>
            </v-col>
          </v-row>
        </div>
        <div v-if="dialogTitle == 'Add User'">
          <v-form ref="addUser">
            <v-row style="align-items: center">
              <v-col cols="6">
                <v-label>First Name</v-label>
                <v-text-field
                  v-model="addUser.first_name"
                  :rules="validateFirstName"
                ></v-text-field
              ></v-col>
              <v-col cols="6">
                <v-label>Last Name</v-label>
                <v-text-field
                  v-model="addUser.last_name"
                  :rules="validateLastName"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row style="align-items: center">
              <v-col cols="12">
                <v-label>Email</v-label>
                <v-text-field
                  v-model="addUser.email"
                  :rules="Email"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn class="add-btn" @click="addUsers()">Add User</v-btn>
          </v-form>
        </div>
      </div>
      <DxToolbarItem
        widget="dxButton"
        toolbar="top"
        location="after"
        :options="closeButtonOptions"
      />
    </DxPopup>
  </div>
</template>
<script>
import {
  getListofUser,
  createUser,
  manageAppAccess,
  changePassword,
  userStateUpdate,
} from "@/api";
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");

import DataGrid from "@components/common/DataGrid.vue";
import { DxPopup, DxToolbarItem } from "devextreme-vue/popup";

export default {
  name: "UserView",

  components: {
    DataGrid,
    DxPopup,
    DxToolbarItem,
  },

  data() {
    return {
      column: [
        {
          caption: "Users",
          dataField: "user.full_name",
          cellTemplate: "usersTemplate",
        },
        {
          caption: "Access",
          dataField: "access",
          cellTemplate: "accessTemplate",
        },
        {
          caption: "Last activity",
          dataField: "lastActivity",
          cellTemplate: "activityTemplate",
        },
        {
          caption: "Status",
          dataField: "user.state",
          cellTemplate: "statusTemplate",
        },
      ],
      appAccessTypes: [
        {
          key: "has_readiness_access",
          text: "Readiness",
          image: "readiness.png",
          close: true,
          textColor: "black",
        },
        {
          key: "has_knowledge_access",
          text: "Knowledge",
          image: "knowledge.png",
          close: true,
          textColor: "black",
        },
        {
          key: "has_performance_access",
          text: "Performance",
          image: "performance.png",
          close: true,
          textColor: "black",
        },
        {
          key: "has_compensation_access",
          text: "Compensation",
          image: "compensation.png",
          close: true,
          textColor: "black",
        },
        {
          key: "has_employee_portal_access",
          text: "Employee Portal",
          image: "employee-portal.png",
          close: true,
          textColor: "black",
        },
      ],
      userList: [],
      status: {
        draft: {
          text: "Draft",
          textColor: "black",
          color: "#e0e0e0",
        },
        active: {
          text: "Active",
          textColor: "black",
          color: "#b8f5ed",
        },
        inActive: {
          text: "Inactive",
          textColor: "white",
          color: "#9b9a9a",
        },
        suspended: {
          text: "Suspended",
          color: "#979ea8",
          textColor: "white",
        },
        disabled: {
          text: "Disabled",
          color: "black",
          textColor: "white",
        },
      },
      dialogTitle: "",
      popupVisible: false,
      closeButtonOptions: {
        icon: "close",
        onClick: () => {
          this.popupVisible = false;
        },
      },
      appPermissions: [],
      password: "",
      validateFirstName: [(v) => !!v || "Please enter first name"],
      validatePassword: [(v) => !!v || "Password is required"],
      validateLastName: [(v) => !!v || "Please enter last name"],
      Email: [
        (v) => !!v || "Please enter your email address",
        (v) =>
          /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/.test(v) ||
          "Please enter your valid email address",
      ],
      currentSelectedRow: {},
      addUser: {
        first_name: "",
        last_name: "",
        email: "",
        state: "draft",
      },
      accountID: "",
    };
  },
  computed: {},
  created() {
    this.accountID = JSON.parse(localStorage.getItem("currentAccountID"));
    this.getUserList();
  },
  methods: {
    ...mapActions(["setLoadingStatus"]),
    getUserList() {
      this.setLoadingStatus(true);
      getListofUser(this.accountID)
        .then((res) => {
          this.userList = res.data.results;
          this.formateData();
        })
        .catch((error) => {
          this.userList = [];
          console.log("error: ", error);
        })
        .finally(() => {
          this.setLoadingStatus(false);
        });
    },

    formateData() {
      this.userList.forEach((element) => {
        element.user.full_name =
          element.user.first_name + " " + element.user.last_name;

        //FOR USER ACCESS CHIPDATA
        element.accessChip = [];
        if (element.has_access) {
          if (element.is_owner) {
            element.accessChip.push({
              color: "black",
              textColor: "white",
              image: "",
              textIsBold: "",
              text: "Owner",
              close: "",
            });
          } else if (element.is_co_owner) {
            element.accessChip.push({
              color: "",
              textColor: "black",
              image: "",
              textIsBold: "",
              text: "Co-Owner",
              close: "",
            });
          } else {
            this.appAccessTypes.forEach((access) => {
              if (element[access.key]) {
                element.accessChip.push(access);
              }
            });
          }
        }

        //FOR USER STATUS CHIPDATA
        element.statusChipData = this.status[element.user.state];

        //FOR USER ACTION MENU
        element.actionMenu = [];
        element.user.state == "draft"
          ? element.actionMenu.push("Activate")
          : element.user.state == "active"
          ? element.actionMenu.push("Suspend")
          : element.user.state == "suspended"
          ? element.actionMenu.push("Re-Activate")
          : "";
        // if (JSON.parse(localStorage.getItem("user")).is_superuser) {
        element.actionMenu.push("Change Password");
        // }
        if (!element.is_owner && !element.is_co_owner) {
          element.actionMenu.push("Manage Apps Access");
        }
      });
      this.userList.sort((a, b) => {
        const nameA = a.user.full_name.toUpperCase();
        const nameB = b.user.full_name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },
    onItemClick(event) {
      this.currentSelectedRow = this.userList.find(
        (x) => x.id === event.rowData.id
      );
      const eventName = event.event.itemData;
      if (eventName === "Change Password") {
        this.dialogTitle = eventName;
        this.popupVisible = true;
      } else if (eventName === "Manage Apps Access") {
        this.appPermissions = [];
        this.dialogTitle = eventName;
        this.appAccessTypes.forEach((access) => {
          if (event.rowData[access.key]) {
            this.appPermissions.push(access.text);
          }
        });
        this.popupVisible = true;
      } else if (
        eventName === "Suspend" ||
        eventName === "Re-Activate" ||
        eventName === "Activate"
      ) {
        this.setLoadingStatus(true);
        let obj = {
          email: this.currentSelectedRow.user.email,
          state: eventName === "Suspend" ? "suspended" : "active",
        };
        userStateUpdate(this.accountID, this.currentSelectedRow.user.id, obj)
          .then(() => {
            this.currentSelectedRow.user.state =
              eventName === "Suspend" ? "suspended" : "active";
            this.formateData();
            this.$toast.success(
              `${this.currentSelectedRow.user.full_name} was ${
                eventName == "Suspend"
                  ? "suspended"
                  : eventName == "Activate"
                  ? "succesfully activated"
                  : "re-activated"
              }.`
            );
          })
          .catch((err) => {
            console.log("err: ", err);
            if (err.response?.data?.detail) {
              this.$toast.error(err.response.data.detail);
            } else {
              this.$toast.error("something went wrong, please try again.");
            }
          })
          .finally(() => {
            this.setLoadingStatus(false);
          });
      }
    },

    addNewUser() {
      this.dialogTitle = "Add User";
      this.popupVisible = true;
    },

    onChangePassword() {
      if (this.$refs.password.validate()) {
        this.setLoadingStatus(true);
        changePassword(this.currentSelectedRow.user.id, {
          password: this.password,
        })
          .then(() => {
            // this.$refs.password.reset();
          })
          .catch((err) => {
            console.log("err: ", err);
            if (err.response?.data?.detail) {
              this.$toast.error(err.response.data.detail);
            } else {
              this.$toast.error("something went wrong, please try again.");
            }
          })
          .finally(() => {
            this.$refs.password.reset();
            this.popupVisible = false;
            this.setLoadingStatus(false);
          });
      }
    },

    onSaveAppAccess() {
      this.setLoadingStatus(true);
      const obj = {
        has_readiness_access: this.appPermissions.includes("Readiness"),
        has_knowledge_access: this.appPermissions.includes("Knowledge"),
        has_performance_access: this.appPermissions.includes("Performance"),
        has_compensation_access: this.appPermissions.includes("Compensation"),
        has_employee_portal_access:
          this.appPermissions.includes("Employee Portal"),
      };
      manageAppAccess(this.accountID, this.currentSelectedRow.user.id, obj)
        .then(() => {
          this.appAccessTypes.forEach((access) => {
            this.currentSelectedRow[access.key] = this.appPermissions.includes(
              access.text
            );
          });
          this.formateData();
        })
        .catch((err) => {
          console.log("err: ", err);
          this.$toast.error("something went wrong, please try again.");
        })
        .finally(() => {
          this.popupVisible = false;
          this.setLoadingStatus(false);
        });
    },

    addUsers() {
      if (this.$refs.addUser.validate()) {
        this.setLoadingStatus(true);
        createUser(this.accountID, this.addUser)
          .then((res) => {
            this.userList.push({
              user: this.addUser,
              id: res.data.id,
            });
            this.formateData();
          })
          .catch((err) => {
            console.log("err: ", err);
            this.$toast.error("something went wrong, please try again.");
          })
          .finally(() => {
            this.popupVisible = false;
            this.setLoadingStatus(false);
          });
      }
    },

    removeAppAccess(event) {
      this.setLoadingStatus(true);
      this.currentSelectedRow = this.userList.find(
        (x) => x.id === event.rowData.id
      );
      const obj = {
        has_readiness_access: event.rowData.has_readiness_access,
        has_knowledge_access: event.rowData.has_knowledge_access,
        has_performance_access: event.rowData.has_performance_access,
        has_compensation_access: event.rowData.has_compensation_access,
        has_employee_portal_access: event.rowData.has_employee_portal_access,
      };
      obj[event.event.key] = false;
      manageAppAccess(this.accountID, this.currentSelectedRow.user.id, obj)
        .then(() => {
          this.currentSelectedRow[event.event.key] = false;
          this.formateData();
          this.$toast.success(
            `${this.currentSelectedRow.user.full_name} has access to ${event.event.text} removed.`
          );
        })
        .catch((err) => {
          console.log("err: ", err);
          this.$toast.error("something went wrong, please try again.");
        })
        .finally(() => {
          this.setLoadingStatus(false);
          // this.popupVisible = false;
        });
    },
  },
};
</script>
<style lang="scss">
.add-btn {
  background-color: #00c2a8 !important;
  color: white !important;
  border-radius: 7px;
  text-transform: none !important;
  width: 150px;
  font-size: 20px;
  box-shadow: 0 !important;
}

.error--text {
  color: red !important;
}

.dx-button-has-icon .dx-icon {
  line-height: 11px;
}
.dx-popup-title {
  background: #ddd !important;
  padding: 10px 20px !important;
}
.v-input--selection-controls {
  margin-top: 0px !important;
}
.v-input__slot .v-label {
  padding-left: 10px !important;
}
</style>
