import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getListofResponsibility = (account_id, page_no, size) => {
  let result = axios()({
    method: "get",
    url: `${API.RESPONSIBILITY}${account_id}/responsibility/`,
    params: { page: page_no, size: size },
  });
  return result;
};

export const createResponsibility = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.RESPONSIBILITY}${account_id}/responsibility/`,
    data: data,
  });
  return result;
};

export const deleteResponsibility = (account_id, res_id) => {
  let result = axios()({
    method: "delete",
    url: `${API.RESPONSIBILITY}${account_id}/responsibility/${res_id}/`,
  });
  return result;
};

export const responsibilityStateUpdate = (account_id, res_id, data) => {
  let result = axios()({
    method: "put",
    url: `${API.RESPONSIBILITY}${account_id}/responsibility/${res_id}/`,
    data: data,
  });
  return result;
};

export const deleteRoleFromResponsibility = (account_id, res_id, role_id) => {
  let result = axios()({
    method: "delete",
    url: `${API.RESPONSIBILITY}${account_id}/responsibility/${res_id}/role-to-responsibility/${role_id}/`,
  });
  return result;
};

export const getListofResponsibilityBadge = (account_id, res_id, type) => {
  let result = axios()({
    method: "get",
    url: `${API.RESPONSIBILITY}${account_id}/${type}/${res_id}/badge/`,
  });
  return result;
};

export const createResponsibilityBadge = (account_id, res_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.RESPONSIBILITY}${account_id}/responsibility/${res_id}/badge/`,
    data: data,
  });
  return result;
};

export const deleteResponsibilityBadge = (account_id, badge_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.RESPONSIBILITY}${account_id}/badge/${badge_id}/remove/`,
    data: data,
  });
  return result;
};

export const getResponsibilityId = (account_id, res_id, type) => {
  let result = axios()({
    method: "get",
    url: `${API.RESPONSIBILITY}${account_id}/${type}/${res_id}/`,
  });
  return result;
};

export const getListofEmployeeOfResId = async (
  account_id,
  page_no,
  size,
  resId
) => {
  let result = await axios()({
    method: "get",
    url: `${API.ACCOUNTList}${account_id}/employee/`,
    params: {
      page: page_no,
      size: size,
      exclude_employees_with_badge_for: resId,
    },
  });
  return result;
};

export const getResponsibilityChatter = (account_id, res_id, page = 1) => {
  let result = axios()({
    method: "get",
    url: `${API.RESPONSIBILITY}${account_id}/responsibility/${res_id}/chatter/`,
    params: { page: page },
  });
  return result;
};

export const getCheckpointsList = (account_id, res_id) => {
  let result = axios()({
    method: "get",
    url: `${API.RESPONSIBILITY}${account_id}/responsibility/${res_id}/checkpoint/`,
  });
  return result;
};

export const addCheckpoint = (account_id, res_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.RESPONSIBILITY}${account_id}/responsibility/${res_id}/checkpoint/create/`,
    data,
  });
  return result;
};

export const getResponsibilityChecklist = (account_id, res_id) => {
  let result = axios()({
    method: "get",
    url: `${API.RESPONSIBILITY}${account_id}/responsibility/${res_id}/checkpoint/`,
  });
  return result;
};

export const createResponsibilityCheckpoint = (account_id, res_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.RESPONSIBILITY}${account_id}/responsibility/${res_id}/checkpoint/create/`,
    data: data,
  });
  return result;
};

export const deleteResponsibilityCheckpoint = (
  account_id,
  res_id,
  checkpoint_id
) => {
  let result = axios()({
    method: "delete",
    url: `${API.RESPONSIBILITY}${account_id}/responsibility/${res_id}/checkpoint/${checkpoint_id}/delete/`,
  });
  return result;
};
export const checkpointStateUpdate = (
  account_id,
  res_id,
  checkpoint_id,
  type
) => {
  let result = axios()({
    method: "post",
    url: `${API.RESPONSIBILITY}${account_id}/responsibility/${res_id}/checkpoint/${checkpoint_id}/${type}/`,
  });
  return result;
};
export const changeCheckpoint = (account_id, res_id, checkpoint_id, data) => {
  let result = axios()({
    method: "patch",
    url: `${API.RESPONSIBILITY}${account_id}/responsibility/${res_id}/checkpoint/${checkpoint_id}/change/`,
    data: data,
  });
  return result;
};
