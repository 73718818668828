var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',[_c('h1',[(_vm.header.img != '')?_c('img',{attrs:{"src":require('@/assets/' + _vm.header.img)}}):_vm._e(),_vm._v(" "+_vm._s(_vm.header.title)+" ")]),_c('DxDataGrid',{attrs:{"id":"data-grid","data-source":_vm.dataSource,"show-borders":true,"column-auto-width":true,"showRowLines":true,"word-wrap-enabled":true,"key-expr":"id","customize-columns":_vm.customizeColumns,"allow-column-resizing":true},scopedSlots:_vm._u([{key:"filterButtonTemplate",fn:function(){return [_c('div',[_c('DxButton',{attrs:{"text":"Filter","icon":"filter"},on:{"click":function($event){_vm.popupVisible = !_vm.popupVisible}}})],1)]},proxy:true},{key:"buttonTemplate",fn:function(){return [_c('div',[_c('v-btn',{staticClass:"add-btn",staticStyle:{"font-size":"18px","width":"auto"},on:{"click":function($event){return _vm.clickOnButton()}}},[_vm._v(_vm._s(_vm.buttonName))])],1)]},proxy:true},{key:"usersTemplate",fn:function({ data: options }){return [_c('UserCard',{attrs:{"user":{
            name:
              options.data.user.first_name +
              ' ' +
              options.data.user.last_name,
            email: options.data.user.email,
            avatar: '',
            linkedToEmployee: false,
            lostAccount:
              options.data.user.state == 'suspended' ||
              options.data.user.state == 'disabled',
          }}})]}},{key:"accessTemplate",fn:function({ data: options }){return [_c('div',_vm._l((options.data.accessChip),function(chipData){return _c('ChipComponent',{key:chipData.text,attrs:{"chipData":chipData},on:{"onClickCloseBtn":function($event){return _vm.onClickClose($event, options.data)}}})}),1)]}},{key:"activityTemplate",fn:function({ data: options }){return [_c('div',[_vm._v(" "+_vm._s(_vm.formatDate(options.data.updated_on))+" ")])]}},{key:"statusTemplate",fn:function({ data: options }){return [_c('ChipComponent',{attrs:{"chipData":options.data.statusChipData}})]}},{key:"employeeTemplate",fn:function({ data: options }){return [_c('EmployeeCard',{attrs:{"employee":{
            name: options.data.full_name,
            title: options.data.title,
            id: options.data.id,
            avatar: options.data.avatar,
          }}})]}},{key:"nameTemplate",fn:function({ data: options }){return [_c('div',{staticClass:"res-name",on:{"click":function($event){return _vm.onClickResName(options.data)}}},[(options.data.nameTemplate.img != '')?_c('img',{attrs:{"src":require('@/assets/' + options.data.nameTemplate.img)}}):_vm._e(),_c('div',[_vm._v(_vm._s(options.data.nameTemplate.name))])])]}},{key:"action",fn:function({ data: rowData }){return [_c('div',[_c('DxDropDownButton',{attrs:{"items":rowData.data.actionMenu,"drop-down-options":{ width: 230 },"icon":"overflow","stylingMode":"text","showArrowIcon":false},on:{"item-click":function($event){return _vm.onItemClick($event, rowData.data)}}})],1)]}}])},[_c('DxPaging',{attrs:{"page-size":20}}),_c('DxPager',{attrs:{"visible":true,"show-navigation-buttons":true}}),_c('DxSearchPanel',{attrs:{"visible":true}}),_c('DxToolbar',[_c('DxItem',{attrs:{"location":"before","template":"filterButtonTemplate"}}),_c('DxItem',{attrs:{"name":"searchPanel","locate-in-menu":"auto","location":"before"}}),_c('DxItem',{attrs:{"template":"buttonTemplate","location":"after"}})],1),_vm._l((_vm.col),function(column){return _c('DxColumn',{key:column.caption,attrs:{"data-field":column.dataField,"caption":column.caption,"allow-search":true}})}),_c('DxColumn',{attrs:{"caption":"Action","cell-template":"action"}})],2),_c('DxPopup',{attrs:{"visible":_vm.popupVisible,"drag-enabled":false,"hide-on-outside-click":false,"show-close-button":false,"show-title":true,"title":"Filter"}},[_c('DxToolbarItem',{attrs:{"widget":"dxButton","toolbar":"top","location":"after","options":_vm.closeButtonOptions}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }