import { getListOfRoles, getListofResponsibility } from "@/api";
import {
  getRoles,
  updateRoles,
  getResponsibilities,
  updateResponsibilities,
} from "@components/helper/localStorageHelper";
export default {
  namespaced: true,
  state: {
    roles: [],
    responsibilities: [],
  },
  mutations: {
    setRoles(state, data) {
      state.roles = data;
    },
    setResponsibilities(state, data) {
      state.responsibilities = data;
    },
  },
  getters: {
    allRoles: (state) => state.roles,
    allResponsibilities: (state) => state.responsibilities,
  },
  actions: {
    async loadRoles({ commit }) {
      try {
        let rolesList = getRoles();
        commit("setRoles", rolesList);
        const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
        getListOfRoles(accountId, 1, 500)
          .then((response) => {
            if (response.data && response.data.results) {
              updateRoles(response.data.results);
              commit("setRoles", response.data.results);
            }
          })
          .catch((error) => {
            console.error("error fetching employees: ", error);
          });
      } catch (e) {
        console.log("error fetching employees: ", e);
      }
    },
    async loadResponsibilities({ commit }) {
      try {
        let responsibilitiesList = getResponsibilities();
        commit("setResponsibilities", responsibilitiesList);
        const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
        getListofResponsibility(accountId, 1, 500)
          .then((response) => {
            if (response.data && response.data.results) {
              updateResponsibilities(response.data.results);
              commit("setResponsibilities", response.data.results);
            }
          })
          .catch((error) => {
            console.error("error fetching employees: ", error);
          });
      } catch (e) {
        console.log("error fetching employees: ", e);
      }
    },
  },
};
