var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',{staticClass:"text-medium-emphasis label-name"},[_vm._v(_vm._s(_vm.label)+"*")]),_c('v-text-field',{staticClass:"response-picker",attrs:{"outlined":"","hide-details":true,"append-icon":(_vm.isInputFocused && _vm.filteredItems.length) ? 'mdi-chevron-up' : 'mdi-chevron-down'},on:{"input":_vm.searchItems,"focus":_vm.onInputFocus,"blur":_vm.onInputBlur,"keydown":_vm.onKeyDown},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [(_vm.isInputFocused)?_c('img',{attrs:{"src":require('@components/assets/search-lg.png'),"alt":"icon"}}):_vm._e()]},proxy:true},{key:"label",fn:function(){return [(_vm.isInputFocused)?_c('label',{staticClass:"response-label-class",class:{ 'custom-label-class': (!_vm.isInputFocused && _vm.localSelectedItem) }},[_vm._v(" "+_vm._s(`Search for ${_vm.label}`)+" ")]):_vm._e(),(!_vm.isInputFocused && !_vm.localSelectedItem)?_c('label',{staticClass:"response-label-class"},[_vm._v(" "+_vm._s(`Search for ${_vm.label}`)+" ")]):_vm._e()]},proxy:true}]),model:{value:(_vm.localSelectedItem),callback:function ($$v) {_vm.localSelectedItem=$$v},expression:"localSelectedItem"}}),(_vm.isListVisible && _vm.filteredItems.length)?_c('v-list',{staticClass:"res-custom-list",class:{
      'res-custom-list-change': _vm.isInputFocused 
    }},_vm._l((_vm.filteredItems),function(item,index){return _c('v-list-item',{key:`item-${index}-${item.id}`,staticClass:"res-v-list-item",class:{
        'highlighted-item': _vm.isSelected(item),
        'new-item': _vm.showPlusIcon(item),
        'last-selected': _vm.lastSelectedItemCode === item.code,
        'item-picker-disabled': _vm.isNewItemDisabled(item)},attrs:{"disabled":_vm.isNewItemDisabled(item)},on:{"click":function($event){return _vm.onListItemClick(item)}}},[(_vm.showPlusIcon(item))?_c('v-list-item-action',[_c('img',{attrs:{"src":require('@components/assets/add_icon.svg'),"alt":"icon"}})]):_vm._e(),(!_vm.showPlusIcon(item))?_c('v-list-item-action',[(_vm.isNewItemDisabled(item))?_c('img',{attrs:{"src":require('@components/assets/list-disable.svg'),"alt":"icon"}}):_c('img',{attrs:{"src":require('@components/assets/list.svg'),"alt":"icon"}})]):_vm._e(),_c('v-list-item-content',{class:{ 'new-item-list': _vm.showPlusIcon(item) }},[(!_vm.showPlusIcon(item) ||
                        (_vm.showPlusIcon(item) && _vm.existingNamesList && _vm.existingNamesList.length > 0 && _vm.existingNamesList.includes(item.name)))?[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"item-code"},[_vm._v("#"+_vm._s(item.code))]),_c('div',{staticClass:"item-name"},[_vm._v(_vm._s(item.name))])])]:[_vm._v(" Create and attach new "+_vm._s(_vm.label)+" \""+_vm._s(item.name)+"\" ")],(_vm.isNewItemDisabled(item))?_c('img',{staticClass:"right-icon",attrs:{"src":require('@components/assets/rightIcon.svg'),"alt":"icon"}}):_vm._e()],2)],1)}),1):_vm._e(),_c('confirmation-dialog',{attrs:{"dialog":_vm.confirmDialog},on:{"confirm":_vm.confirmAddition,"cancel":_vm.cancelAddition},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Adding New "+_vm._s(_vm.label)+" ")]},proxy:true},{key:"content",fn:function(){return [_vm._v(" Are you sure you want to create and attach "+_vm._s(_vm.label)+" "+_vm._s(_vm.localSelectedItem)+" ? ")]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }