import { getListOfPositions } from "@/api";
import {
  getPositions,
  updatePositions,
} from "@components/helper/localStorageHelper";
export default {
  namespaced: true,
  state: {
    positions: [],
    activePositionsCount: 0,
    totalPositionsCount: 0,
  },
  mutations: {
    setPositions(state, positions) {
      state.positions = positions;
      const activePositions = positions.filter(
        (el) => el.status == "active"
      ).length;
      state.activePositionsCount = activePositions;
    },
    setActivePositionsCount(state, count) {
      state.activePositionsCount = count;
    },
    setTotalPositionsCount(state, count) {
      state.totalPositionsCount = count;
    },
  },
  actions: {
    updateActivePositionsCount({ commit }, count) {
      commit("setActivePositionsCount", count);
    },
    async loadPositions({ state, commit }) {
      try {
        let positionsList = getPositions();
        commit("setPositions", positionsList);
        const accountId = JSON.parse(localStorage.getItem("currentAccountID"));
        getListOfPositions(accountId, 1, 500)
          .then((response) => {
            if (response.data && response.data.count) {
              state.totalPositionsCount = response.data.count;
            }
            if (response.data && response.data.results) {
              updatePositions(response.data.results);
              commit("setPositions", response.data.results);
            }
          })
          .catch((error) => {
            console.error("error fetching employees: ", error);
          });
      } catch (e) {
        console.log("error fetching employees: ", e);
      }
    },
  },
  getters: {
    positions: (state) => state.positions,
    activePositionsCount: (state) => state.activePositionsCount,
    totalPositionsCount: (state) => state.totalPositionsCount,
  },
};
