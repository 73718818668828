import axios from "./_axios";
import { API } from "./apiEndPoints";

export const getListofRoles = (account_id, page, size) => {
  try {
    let result = axios()({
      method: "get",
      url: `${API.ACCOUNTList}${account_id}/role/`,
      data: { page: page, size: size },
    });
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const createRole = (account_id, data) => {
  let result = axios()({
    method: "post",
    url: `${API.ACCOUNTList}${account_id}/role/`,
    data: data,
  });
  return result;
};

export const deleteRole = (account_id, res_id) => {
  let result = axios()({
    method: "delete",
    url: `${API.ACCOUNTList}${account_id}/role/${res_id}/`,
  });
  return result;
};

export const roleStateUpdate = (account_id, res_id, data) => {
  let result = axios()({
    method: "put",
    url: `${API.ACCOUNTList}${account_id}/role/${res_id}/`,
    data: data,
  });
  return result;
};
