<template>
  <v-container fluid class="d-flex justify-center py-0 px-0">
    <div class="py-0 pr-0 ex-login">
      <div class="login-page d-flex justify-space-between">
        <div class="login-part flex-grow-1 px-12">
          <LoginForm
            :logintype="'account'"
            @login="login($event)"
            :alert="alert"
            :accountlist="accountlist"
            :displayLogin="displayLogin"
            @onSelectAccount="onSelectAccount($event)"
            @closeError="alert = {}"
          ></LoginForm>
        </div>
        <div
          class="login-right flex-shrink-1 d-lg-flex justify-end"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <img
            :src="require('@/assets/login/Section1.png')"
            style="height: 100vh; max-width: 100%; object-fit: contain"
          />
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>
import LoginForm from "@components/login/LoginForm.vue";
import { loginEmailPW, getListofAccount, getAppAccess } from "@/api";
// import { knock } from "@/plugins/knock";

import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("isLoading");
const { mapState } = createNamespacedHelpers("isPosition");
export default {
  name: "LoginView",

  components: {
    LoginForm,
  },

  data() {
    return {
      alert: {
        type: "",
        message: "",
      },
      accountlist: [],
      displayLogin: true,
    };
  },
  computed: {
    ...mapState(["positions", "activePositionsCount"]),
  },
  created() {},
  methods: {
    ...mapActions(["setLoadingStatus"]),
    async login(event) {
      this.setLoadingStatus(true);

      loginEmailPW(event)
        .then((result) => {
          const user = result.data.user;
          localStorage.clear();
          localStorage.setItem("token", result.data.token);
          localStorage.setItem("user", JSON.stringify(user));

          getListofAccount(result.data.user.id)
            .then(async (res) => {
              this.accountlist = res.data.results;
              if (res.data.results.length == 0) {
                localStorage.clear();
                this.alert = {
                  type: "error",
                  message: "You have no accounts permissions",
                };
              } else if (res.data.results.length == 1) {
                const account = res.data.results[0];
                await this.onSelectAccount(account);
              } else {
                this.accountlist.sort((a, b) => {
                  const nameA = a.name.toUpperCase();
                  const nameB = b.name.toUpperCase();
                  if (nameA < nameB) {
                    return -1;
                  }
                  if (nameA > nameB) {
                    return 1;
                  }
                  return 0;
                });
                this.displayLogin = false;
              }
            })
            .catch((err) => {
              console.log("err: ", err);
            });
        })
        .catch((error) => {
          if (error.response?.data.error) {
            this.alert = {
              type: "error",
              message: error.response.data.error,
            };
          } else {
            this.alert = {
              type: "error",
              message: "Please try again, something went wrong.",
            };
          }
        })
        .finally(() => {
          this.setLoadingStatus(false);
        });
    },

    async onSelectAccount(account) {
      try {
        if (!account || !account.id) throw "no account";
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user) throw "no user";
        localStorage.setItem("currentAccountID", account.id);
        const response = await getAppAccess(account.id, user.id);
        localStorage.setItem("accessPermission", JSON.stringify(response.data));
        const currentAccount = JSON.parse(
          localStorage.getItem("accessPermission")
        );
        if (!currentAccount?.has_employee) {
          await this.$router.push({ name: "Employees" });
        } else if (currentAccount?.has_employee) {
          await this.$router.push({ name: "MyStuff" });
        } else {
          await this.$router.push({ name: "User" });
        }
      } catch (e) {
        console.error("error on login: ", e);
        this.$router.go(0);
      }
    },
  },
};
</script>
<style>
.ex-login {
  width: 100%;
  max-width: 1600px;
}
.login-page {
  height: 100%;
  width: 100%;
  background: #ffffff;
  margin: 0 !important;

  .login-part {
    padding: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .welcome-title {
      font-style: normal;
      font-size: 30px;
      line-height: 38px;
      font-weight: 700;
      text-align: center;
      color: #475467;
    }
    .welcome-subtitle {
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      color: #475467;
      font-weight: 700;
      text-align: center;
    }
  }

  .login-right {
    padding: 0;
  }
}
.login-custom-list {
  padding: 8px;
}
.login-picker
  .v-input__control
  .v-input__slot
  .v-text-field__slot
  .theme--light.v-label {
  padding-left: 0px !important;
}
</style>
