var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex allocation_icon mr-2"},[_c('div',{staticClass:"circle"},[_c('div',{staticClass:"content",class:{
          bg_all_gray: _vm.type === '',
          bg_black: _vm.type === 'occupied' || _vm.type === 'vacant' || _vm.type === 'blocked',
        }})]),_c('div',{staticClass:"square"},[_c('div',{staticClass:"content",class:{
          bg_all_gray: _vm.type === '',
          bg_gray: _vm.type === 'occupied',
          bg_white: _vm.type === 'vacant',
          bg_black: _vm.type === 'blocked',
        }})]),_c('div',{staticClass:"content d-flex justify-center align-center"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(_vm.fte))])])]),(_vm.type === 'occupied')?_c('v-avatar',{attrs:{"data-name":"avatar","size":"24","color":"#F9F5FF"}},[(_vm.photoUrl)?_c('img',{attrs:{"src":_vm.photoUrl,"alt":"employee photo"}}):_c('span',{staticClass:"text-avatar"},[_vm._v(_vm._s(`${_vm.firstName[0]}${_vm.lastName[0]}`))])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }